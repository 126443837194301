<template>
  <FormCard
    :title="productTitle"
    :status="product.status"
    @save="validate"
    @cancel="$router.push({ name: 'products' })"
  >
    <v-form
      v-if="currentTabs"
      class="px-5 mt-5"
      ref="form"
    >
      <v-tabs v-model="currentTab">
        <v-tabs-slider color="primary" />

        <v-tab
          v-for="item in currentTabs"
          :key="item.title"
        >
          {{ item.title }}
        </v-tab>

        <v-tabs-items v-model="currentTab">
          <v-tab-item
            v-for="item in currentTabs"
            :key="item.title"
            class="py-10"
          >
            <component
              :product="product"
              :is="item.component"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-form>
    <v-dialog
      v-model="showWarningModal"
      max-width="450"
      persistent
    >
      <v-card class="pa-5">
        <v-card-title class="headline text-center">
          <v-icon
            color="warning"
            class="mr-2"
            >fas fa-exclamation-triangle</v-icon
          >
          Atenção
        </v-card-title>
        <v-card-text class="text-center">
          <div
            v-html="warningMessage"
            class="text-left"
          ></div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            @click="showWarningModal = false"
          >
            <v-icon left>fas fa-check</v-icon> OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </FormCard>
</template>

<script>
export default {
  props: {
    valid: Boolean,
    product: {
      type: Object,
      required: true,
    },
    composed: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormCard: () => import('@/components/FormCard'),
    MainData: () => import('./form-sections/MainData'),
    PrintData: () => import('./form-sections/PrintData'),
    Media: () => import('./form-sections/Media'),
    Vendors: () => import('./form-sections/Vendors'),
    Categories: () => import('./form-sections/Categories'),
    Composition: () => import('./form-sections/Composition'),
  },
  data: () => ({
    currentTab: null,
    currentTabs: [],
    tabsItems: [
      { title: 'Dados Principais', component: 'main-data' },
      { title: 'Gravação', component: 'print-data' },
      { title: 'Mídias', component: 'media' },
      { title: 'Categorias', component: 'categories' },
      { title: 'Fornecedores', component: 'vendors' },
    ],
    tabsItemsComposed: [
      { title: 'Dados Principais', component: 'main-data' },
      { title: 'Gravação', component: 'print-data' },
      { title: 'Mídias', component: 'media' },
      { title: 'Categorias', component: 'categories' },
      { title: 'Composição', component: 'composition' },
    ],
    profile: null,
    showWarningModal: false,
    warningMessage: '',
  }),
  computed: {
    isValid: {
      get: function () {
        return this.valid
      },
      set: function (value) {
        this.$emit('validate', value)
      },
    },
    productTitle() {
      if (this.product.product_reference) {
        return (
          this.product.product_reference + ' - ' + this.product.product_name
        )
      } else if (this.product.id) {
        return this.product.id + ' - ' + this.product.product_name
      }

      return 'Novo Produto'
    },
    isSaveDisabled() {
      const hasImages =
        this.product.product_images && this.product.product_images.length > 0
      const hasColorSelected = this.product.product_images.some(
        (img) => img.color_id
      )
      return !(hasImages && hasColorSelected)
    },
  },
  methods: {
    validate() {
      // Lista de campos obrigatórios ausentes
      const missingFields = []

      if (!this.product.product_name) missingFields.push('Nome do Produto')
      if (!this.product.product_reference) missingFields.push('Referência')
      if (!this.product.product_description) missingFields.push('Descrição')
      if (
        !this.product.product_vendors ||
        this.product.product_vendors.length === 0
      ) {
        missingFields.push('Fornecedor')
      }
      if (!this.product.categories || this.product.categories.length === 0) {
        missingFields.push('Categoria')
      }

      // Verifica se há pelo menos uma imagem cadastrada
      // const hasImages =
      //   this.product.product_images && this.product.product_images.length > 0
      // Verifica se há pelo menos uma cor associada a alguma imagem
      // const hasColorSelected =
      //   hasImages && this.product.product_images.some((img) => img.color_id)

      // if (!hasImages) missingFields.push('Imagem do Produto')
      // if (!hasColorSelected) missingFields.push('Cor associada à imagem')

      // Se algum campo ainda estiver ausente, exibir o modal de aviso
      if (missingFields.length > 0) {
        this.warningMessage = `
        <p>Os seguintes campos são <strong>obrigatórios</strong> e precisam ser preenchidos:</p>
        <ul>
          ${missingFields
            .map((field) => `<li><strong>${field}</strong></li>`)
            .join('')}
        </ul>
      `
        this.showWarningModal = true
        return
      }

      // Se tudo estiver preenchido corretamente, dispara o evento de salvar
      this.$emit('save')
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Beader ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }
  },
  mounted() {
    console.log(this.composed)
    if (this.composed) {
      this.currentTabs = this.tabsItemsComposed
      this.product.is_composition = true
      console.log('É composto: ', this.currentTabs)
    } else {
      this.currentTabs = this.tabsItems
      this.product.is_composition = false
      console.log('NÃO é composto: ', this.currentTabs)
    }
  },
}
</script>

<style scoped>
.v-card-title {
  font-weight: bold;
}

.v-card-text ul {
  list-style-type: none;
  padding: 0;
}

.v-card-text li {
  background: rgba(255, 193, 7, 0.2);
  border-left: 4px solid #ffc107;
  padding: 8px;
  margin: 5px 0;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  border-radius: 5px;
}
</style>
